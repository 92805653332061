/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import classNames from 'classnames';
import Iframe from 'react-iframe';
import { useSpring, animated } from 'react-spring';
import { navigate } from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';

import Layout from '../components/layout';
import SEO from '../components/seo';
import './program.scss';

const downIcon = require('../images/icon-arrow-down-blue@3x.png');
const nextIcon = require('../images/icon-arrow-next-gray@3x.png');
const weekcallIcon = require('../images/weekly-call@3x.png');
const airtableIcon = require('../images/airtable-icon.png');
const airtableImg = require('../images/airtable-app@3x.png');
const productTeamImg = require('../images/product-team@3x.png');
const collaborationImg = require('../images/collaboration@3x.png');
const isabelImg = require('../images/isabel.png');
const clientImg = require('../images/client.png');

const APPS = {
  PLAY_ME: 0,
  CHARACTERID: 1,
  GODSGIFT: 2,
  PEEPFUNDS: 3,
  // FLAWK: 4,
};

const IFRAMES = {
  0: 'https://marvelapp.com/6gfh43j?emb=1&iosapp=false&frameless=false',
  1: 'https://marvelapp.com/6gfh43j?emb=1&iosapp=false&frameless=false',
  2: 'https://marvelapp.com/6gfh43j?emb=1&iosapp=false&frameless=false',
  3: 'https://marvelapp.com/6gfh43j?emb=1&iosapp=false&frameless=false',
  4: 'https://marvelapp.com/6gfh43j?emb=1&iosapp=false&frameless=false',
};

const AppItem = ({
  name, currentApp, appType, startPrototype, time, selectApp,
}) => {
  const appFade = (index) => (startPrototype ? useSpring({
    opacity: 1, transform: 'translateX(0)', delay: index * 100, config: { duration: 200 },
  }) : useSpring({ opacity: 0, transform: 'translateX(-50px)' }));

  const [isHover, setHover] = useState(false);

  return (
    <animated.div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={appFade(time)}
      onClick={() => selectApp(appType)}
      className={classNames('Program__Apps__List_Item', { 'Program__Apps__List_Item-active': isHover || currentApp === appType })}
    >
      {name}
    </animated.div>
  );
};

const Program = () => {
  const [currentApp, selectApp] = useState(APPS.PLAY_ME);
  const [startContent, focusContent] = useState(false);
  const [startProduct1, focusProduct1] = useState(false);
  const [startProduct2, focusProduct2] = useState(false);
  const [startProduct3, focusProduct3] = useState(false);
  const [startPrototype, focusPrototype] = useState(false);
  const [startFounder, focusFounder] = useState(false);

  const fade = (index) => useSpring({
    opacity: 1, delay: index * 300, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(60px)' }, config: { duration: 300 },
  });

  const onFocusContent = (isVisible) => {
    if (!startContent) focusContent(isVisible);
  };
  const fadeContent = startContent ? useSpring({
    opacity: 1, transform: 'translateY(0)', config: { duration: 300 },
  }) : useSpring({ opacity: 0, transform: 'translateY(-300px)' });

  const onFocusProduct1 = (isVisible) => {
    if (!startProduct1) focusProduct1(isVisible);
  };
  const onFocusProduct2 = (isVisible) => {
    if (!startProduct2) focusProduct2(isVisible);
  };
  const onFocusProduct3 = (isVisible) => {
    if (!startProduct3) focusProduct3(isVisible);
  };
  const fadeProduct = (type, index) => (type ? useSpring({
    opacity: 1, transform: 'translateY(0)', delay: index * 200, config: { duration: 300 },
  }) : useSpring({ opacity: 0, transform: 'translateY(50px)' }));

  const onFocusPrototype = (isVisible) => {
    if (!startPrototype) focusPrototype(isVisible);
  };

  const onFocusFounder = (isVisible) => {
    if (!startFounder) focusFounder(isVisible);
  };
  const appFade = (index) => (startPrototype ? useSpring({
    opacity: 1, transform: 'translateX(0)', delay: index * 100, config: { duration: 200 },
  }) : useSpring({ opacity: 0, transform: 'translateX(-50px)' }));

  const iframeFade = (index) => (startPrototype ? useSpring({
    opacity: 1, transform: 'translateY(0)', delay: index * 100, config: { duration: 200 },
  }) : useSpring({ opacity: 0, transform: 'translateY(60px)' }));

  return (
    <Layout>
      <SEO title="Program" />
      <div className="Program">
        <div>
          <animated.div style={fade(0)} className="Program__Title">
            Prototype in 4
            {' '}
            <span className="Program__Highlight">weeks</span>
          </animated.div>
          <animated.div style={fade(1)} className="Program__Description">
            Get your own remote startup team to focus and test your idea with a
            <br />
            design prototype.
          </animated.div>
          <animated.button style={fade(2)} onClick={() => navigate('/contact-us/')} type="button" className="Program__Applybtn"> Apply now </animated.button>
          <animated.div style={fade(3)} className="Program__Img" />
        </div>
        <div>
          <VisibilitySensor onChange={onFocusContent}>
            <animated.div style={fadeContent}>
              <div className="Program__NextText">
                What will
                <br />
                you get?
              </div>
              <img src={downIcon} alt="" className="Program__Downbtn" />
            </animated.div>
          </VisibilitySensor>
        </div>
        <div className="Program__Products">
          <animated.div style={fadeProduct(startProduct1, 0)} className="Program__Products__Item">
            <VisibilitySensor onChange={onFocusProduct1}>
              <div className="Program__Products__Item__Link">Weekly Strategy Calls</div>
            </VisibilitySensor>
            <div className="Program__Products__Item__Title">
              Paired with your
              own product team
            </div>
            <div className="Program__Products__Item__Des">Learn an iterative process in weekly live video calls. Every week, address your challenges and acknowledge accomplishments with a team.</div>
          </animated.div>
          <animated.div style={fadeProduct(startProduct1, 1)} className="Program__Products__Item">
            <img src={weekcallIcon} alt="" />
          </animated.div>
          <animated.div style={fadeProduct(startProduct2, 0)} className="Program__Products__Item">
            <img src={airtableImg} alt="" />
          </animated.div>
          <animated.div style={fadeProduct(startProduct2, 1)} className="Program__Products__Item">
            <VisibilitySensor onChange={onFocusProduct2}>
              <div className="Program__Products__Item__Link"><img src={airtableIcon} alt="" /></div>
            </VisibilitySensor>
            <div className="Program__Products__Item__Title">
              Actionable startup
              <br />
              system
            </div>
            <div className="Program__Products__Item__Des">As a solo founder or small team, time and resources are limited. Manage everything in one place for clear priorities and easy collaboration.</div>
          </animated.div>
          <animated.div style={fadeProduct(startProduct3, 0)} className="Program__Products__Item">
            <VisibilitySensor onChange={onFocusProduct3}>
              <div className="Program__Products__Item__Link"><img src={productTeamImg} alt="" /></div>
            </VisibilitySensor>
            <div className="Program__Products__Item__Title">
              Online collaboration
              <br />
              and support
            </div>
            <div className="Program__Products__Item__Des">Weekly check-ins & online collaboration to stay focused on what’s most important and move your idea forward.</div>
          </animated.div>
          <animated.div style={fadeProduct(startProduct3, 1)} className="Program__Products__Item">
            <img src={collaborationImg} alt="" />
          </animated.div>
        </div>
        <VisibilitySensor onChange={onFocusPrototype}>
          <div className="Program__Prototype">
            <animated.div style={fadeProduct(startPrototype, 1)} className="Program__Prototype__Title">Design Prototype</animated.div>
            <animated.div style={fadeProduct(startPrototype, 2)} className="Program__Prototype__Des">
              Clear roadmap for development and high-fidelity design
              <br />
              prototype that you can validate with users.
            </animated.div>
          </div>
        </VisibilitySensor>
        <div className="Program__Apps">
          <div className="Program__Apps__Left">
            <div className="Program__Apps__List">
              <AppItem
                appType={APPS.PLAY_ME}
                currentApp={currentApp}
                name="Playme Social"
                startPrototype={startPrototype}
                time={3}
                selectApp={selectApp}
              />
              <AppItem
                appType={APPS.CHARACTERID}
                currentApp={currentApp}
                name="CharacterID"
                startPrototype={startPrototype}
                time={4}
                selectApp={selectApp}
              />
              <AppItem
                appType={APPS.GODSGIFT}
                currentApp={currentApp}
                name="God’s Gifts"
                startPrototype={startPrototype}
                time={5}
                selectApp={selectApp}
              />
              <AppItem
                appType={APPS.PEEPFUNDS}
                currentApp={currentApp}
                name="Peepfunds"
                startPrototype={startPrototype}
                time={6}
                selectApp={selectApp}
              />
              {/* <AppItem
                appType={APPS.FLAWK}
                currentApp={currentApp}
                name="Flawk"
                startPrototype={startPrototype}
                time={7}
                selectApp={selectApp}
              /> */}
            </div>
            <animated.div style={appFade(9)} className="Program__Apps__Guide">
              <div>
                <div className="Body80Center">
                  Tap on mobile
                  <br />
                  screen to interact
                  <br />
                  the prototype
                </div>
                <div className="ButtonSmallPurpleCenter">Got it!</div>
              </div>
              <img src={nextIcon} alt="" style={{ width: 50, height: 24, marginLeft: 20 }} />
            </animated.div>
          </div>
          <animated.div style={iframeFade(10)} className="Program__Apps__Iframe">
            <Iframe
              url={IFRAMES[currentApp]}
              id="myId"
              className="Program__Apps__Iframe_content"
              display="initial"
              position="relative"
              frameBorder={0}
            />
          </animated.div>
        </div>
        <div className="Program__Founder">
          <VisibilitySensor onChange={onFocusFounder}>
            <animated.div style={fadeProduct(startFounder, 1)} className="Program__Founder__Title">Moving founders forward</animated.div>
          </VisibilitySensor>
          <animated.div style={{
            ...fadeProduct(startFounder, 2), display: 'flex', flexDirection: 'column', alignItems: 'flex-end',
          }}
          >
            <div className="Program__Founder__Des">
              “Flight Plan has been a game changer
              to help me prove my idea on a small
              budget. It was a thrill to see the first
              design of something I’ve been working
              so hard on!”
            </div>
            <div className="Program__Founder__Info">
              <img src={isabelImg} alt="" style={{ width: 80, height: 80, margin: 0 }} />
              <div className="Program__Founder__Info_isabel">
                <div className="H4BlackLeft">Isabel Reed</div>
                <div className="Body80Left">Founder, Comigo & Student, U of Iowa</div>
              </div>
              <img src={clientImg} alt="" style={{ width: 80, height: 80, margin: 0 }} />
            </div>
          </animated.div>
        </div>
        {/*
          <div className="Program__ProductTeam">
            <div className="Program__ProductTeam__Title">
              Product team of
              <br />
              founders & designers
            </div>
            <div className="Program__ProductTeam__Des">
              We move your idea forward in 4 weeks
              <br />
              with product strategy and design ready
              <br />
              for development.
            </div>
          </div>
          <div className="Program__ProductTeam__List">
            <div className="Program__ProductTeam__List__Item" />
            <div className="Program__ProductTeam__List__Item" />
            <div className="Program__ProductTeam__List__Item" />
            <div className="Program__ProductTeam__List__Item" />
          </div>
          <div className="Program__Founder">
            <div className="Program__Founder__Title">Moving founders forward</div>
            <div>
              <div className="Program__Founder__Des">
                “Flight Plan has been a game changer
                <br />
                to help me prove my idea on a small
                <br />
                budget. It was a thrill to see the first
                <br />
                design of something I’ve been working
                <br />
                so hard on!”
              </div>
            </div>
          </div>
         */}
      </div>
    </Layout>
  );
};

export default Program;
